import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import ServiceBanner from "./ServiceBanner";
import ServiceCommon from "./ServiceCommon";
import ServiceCommonOdd from "./ServiceCommonOdd";
import {} from "../../utils/business_intelligence";
import {
  artificial_intelligence_banner_data,
  artificial_intelligence_page_data,
} from "../../utils/artificial_intelligence";
import { Link } from "react-router-dom";
import FooterTop from "../../components/FooterTop";
const AI = () => {
  const heading =
    "Join our team of passionate DevOps professionals! Explore career opportunities at InfoVertex and be part of our innovative and dynamic culture";
  const text = "You Can Join Us";
  const link = "career";

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Artificial Intelligence | InfoVertex">
      <ServiceBanner
        title={artificial_intelligence_banner_data.title}
        desc={artificial_intelligence_banner_data.desc}
        properties={artificial_intelligence_banner_data.properties}
        image={artificial_intelligence_banner_data.image}
      />

      <div className="container">
        <h1 className="text-center fw-light service-heading">Services</h1>
      </div>
      {artificial_intelligence_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

      

      <div className="padding-last"></div>

 
    </Layout>
  );
};

export default AI;
