import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import ServiceBanner from "./ServiceBanner";
import ServiceCommonOdd from "./ServiceCommonOdd";
import ServiceCommon from "./ServiceCommon";
import {
  data_and_analytics_banner_data,
  data_and_analytics_page_data,
} from "../../utils/data_and_analytics";
import { Link } from "react-router-dom";
import FooterTop from "../../components/FooterTop";

const DA = () => {
  const heading =
    "Join our team of passionate DevOps professionals! Explore career opportunities at InfoVertex and be part of our innovative and dynamic culture";
  const text = "You Can Join Us";
  const link = "career";

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Data and Analytics | InfoVertex">
      <ServiceBanner
        title={data_and_analytics_banner_data.title}
        desc={data_and_analytics_banner_data.desc}
        properties={data_and_analytics_banner_data.properties}
        image={data_and_analytics_banner_data.image}
      />
      <div className="container">
        <h1 className="text-center fw-light service-heading">Services</h1>
      </div>
      {data_and_analytics_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

      

      <div className="padding-last"></div>

          </Layout>
  );
};

export default DA;
