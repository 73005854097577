import React from "react";
import Banner100 from "../../components/Banner100";
const ServiceBanner = ({ title, image, properties, desc }) => {
  return (
    <>
       <Banner100 title={title} desc={desc} image={image} />
       
      {/* <div className="service-banner-cards">
        <div className="container">
          <div className="row">
            {properties.map((property, index) => (
              <div className="col-lg-4" key={index}>
                <div className="bottom-card">
                  <h4>{property.title}</h4>
                  <p>{property.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ServiceBanner;
