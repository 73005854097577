import React from 'react'
import Layout from '../../components/layout/Layout'
import Banner100 from '../../components/Banner100'
import zonosenth from "../../img/dcba-senth.svg";

const AcceleratorThree = () => {

  const title="xxxxxxxxxxxxxxxxxxx"
  const desc="A state-of-the-art generative AI-based synthetic data generator that provides businesses with a reliable and realistic solution for their analytics and machine learning needs."
  return (
    <Layout title="AcceleratorThree | InfoVertex">
    <Banner100 title={title} desc={desc} image={zonosenth} />
    </Layout>
   
  )
}

export default AcceleratorThree