import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const FooterTop = ({heading,text,link}) => {
  return (
    <div className="bottom">
        <div className="container position-relative  ">
          <div className="top-minus text-center">
            <h2 className="fw-light mb-4">
              {heading}
            </h2>

            <Link to={`/${link}`} className="contact-us">
              <BsArrowRight className="btn-icon" />
              <span>{text}</span>{" "}
            </Link>
          </div>
        </div>
      </div>
  )
}

export default FooterTop