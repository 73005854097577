import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import Banner100 from "../../components/Banner100";

import onshoreHero from "../../img/onshoreHero.svg";
import ServiceCommon from "../services/ServiceCommon";
import ServiceCommonOdd from "../services/ServiceCommonOdd";
import { onshoring_staff_page_data } from "../../utils/staffing";
import FooterTop from "../../components/FooterTop";
import { Link } from "react-router-dom";




const Onshore = () => {
  const title = "Onshore Staffing";
  const desc =
    "At InfoVertex, we provide high quality Onshore Staffing solutions. We connect talent with opportunity right where you are, fostering collaboration, efficiency, and growth within your local landscape.";

  const heading =
    "Join our team of passionate DevOps professionals! Explore career opportunities at InfoVertex and be part of our innovative and dynamic culture";
  const text = "Join Us";
  const link = "career";

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Onshore Stafing | InfoVertex">
      <Banner100 title={title} desc={desc} image={onshoreHero} />

      <div className="container">
        <h1 className="text-center fw-light service-heading">
          Our Onshore Staffing Solutions
        </h1>
      </div>

      {onshoring_staff_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

   

      <div className="padding-last"></div>
    </Layout>
  );
};

export default Onshore;
