import React from 'react'

const Banner100 = ({title,desc,image}) => {
  return (
    <div className="server-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 className="white mb-4">{title}</h1>
              <p className="white-para">{desc}</p>
            </div>
            <div className="col-lg-6 mt-4 text-center mt-lg-0">
              <img
                src={image}
                alt="digital engineering"
                className="img-fluid banner-img"
              />
            </div>
          </div>
        </div>
      </div>

  )
}

export default Banner100