import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/home/Homepage";
import AI from "./pages/services/AI";
import BI from "./pages/services/BI";
import DA from "./pages/services/DA"; 
import AcceleratorTwo from "./pages/accelertors/AcceleratorTwo";
import AcceleratorThree from "./pages/accelertors/AcceleratorThree";
import AcceleratorFour from "./pages/accelertors/AcceleratorFour";
import AboutUs from "./pages/AboutUs";
import Partnership from "./pages/Partnership";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import DevOps from "./pages/services/DevOps";
import IT from "./pages/services/IT";
import Contiguent from "./pages/staffing/Contiguent";
import Onshoure from "./pages/staffing/Onshore";
import Offshoure from "./pages/staffing/Offshoure";
import Stories from "./pages/Stories";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />


        <Route path="/artificial-intelligence" element={<AI />} />
        <Route path="/business-intelligence" element={<BI />} />
        <Route path="/data-and-analytics" element={<DA />} />
        <Route path="/devops" element={<DevOps />} />
        <Route path="/managed-it-solutions" element={<IT />} />

        <Route path="/contingent-staffing" element={<Contiguent />} />
        <Route path="/onshore-staffing" element={<Onshoure />} />
        <Route path="/offshore-staffing" element={<Offshoure />} />


        <Route path="/client-stories" element={<Stories />} />
        
        
        <Route path="/
dcba-lens" element={<AcceleratorTwo />} />
        <Route path="/dcba-synth" element={<AcceleratorThree />} />
              <Route path="/dcba-assist" element={<AcceleratorFour />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<Contact />} />

        </Routes>

    </>
  );
};

export default App;