
import check from "../icons/check.svg"; 
import devopsHero from "../img/devops.svg";
import operations from "../img/operations.svg";
import devopsService from "../icons/devops-service.svg";
import devopsTech from "../img/devops-tech.png";
import generativeAiMain from "../img/generative-ai-main.svg";
import dataManagement from "../img/data-management.svg";
 
export const devops_page_data = [
  {
    service_title: "Developement Plus Operations",
    service_desc:"We provide seamless DevOps solutions that accelerate development cycles, enhance collaboration, and drive efficiency. Transform your software delivery process with our tailored DevOps services.",
    image: operations,
    service_items: [
      {
        title: "Continuous Integration & Deployment",
        desc: "Automate your build and deployment processes, reducing errors and accelerating releases.",
        alt: "Continuous Integration & Deployment",
        icon: devopsService,
      },
      {
        title: "Infrastructure as Code (IAC)",
        desc: "Manage and provision your computing resources efficiently through code.",
        alt: "Infrastructure as Code (IAC)",
        icon: devopsService,
      },
      {
        title: "Monitoring & Performance Optimization",
        desc: "Monitor application performance and optimize for efficiency and scalability.",
        alt: "Monitoring & Performance Optimization",
        icon: devopsService,
      },
      {
        title: "Cloud & Containerization Services",
        desc: "Leverage cutting-edge cloud and containerization technologies for flexible, robust solutions.",
        alt: "Cloud & Containerization Services",
        icon: devopsService,
      },
      
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc:"Discover the potential of DevOps tailored to your business needs. Collaborate with us to effectively implement DevOps solutions that align with your business objectives.",
    image: generativeAiMain,
    service_items: [
      {
        title: "Expertise",
        desc: "Our skilled DevOps engineers have the experience to craft bespoke solutions.",
        alt: "Expertise",
        icon: check,
      },
      {
        title: "Collaboration",
        desc: "We work closely with you to align our services with your business goals.",
        alt: "Collaboration",
        icon: check,
      },
      {
        title: "Innovation",
        desc: "Stay ahead of the curve with our forward-thinking approaches and technologies.",
        alt: "Innovation",
        icon: check,
      },
      {
        title: "24/7 Support",
        desc: "Our support team is on hand to provide assistance whenever you need it.",
        alt: "24/7 Support",
        icon: check,
      },
    ],
  },
  {
    service_title: "Technologies We Work With",
    service_desc: "We work with the latest technologies to deliver cutting-edge DevOps solutions that drive efficiency and innovation. Explore the technologies we use to help you achieve your business goals.",
    image: devopsTech,
    service_items: [
      {
        title: "AWS, Azure, Google Cloud",
        desc: "Harness the power of top-tier cloud platforms (AWS, Azure, Google Cloud) to elevate your business scalability and agility",
        alt: "AWS, Azure, Google Cloud",
        icon: check,
      },
      {
        title: "Kubernetes, Docker",
        desc: "Unlock the potential of containerization and orchestration with Kubernetes and Docker for seamless application deployment and management.",
        alt: "Kubernetes, Docker",
        icon: check,
      },
      {
        title: "Jenkins, Git, Ansible",
        desc: "Streamline your development lifecycle using the synergy of Jenkins, Git, and Ansible for efficient automation, version control, and configuration management",
        alt: "Jenkins, Git, Ansible",
        icon: check,
      },
       
    ],
  },
   
  {
    service_title: "Industries We Serve",
    service_desc: "InfoVertex specializes in delivering tailored DevOps solutions across diverse sectors, including healthcare, Healthcare, E-commerce, and Manufacturing. Our insights drive efficiency and growth in these critical industries.",
    image: dataManagement,
    service_items: [
      {
        title: "Fintech",
        desc: "Streamlining financial operations through advanced DevOps solutions.",
        alt: "Fintech",
        icon: check,
      },
      {
        title: "Healthcare IT",
        desc: "Enhancing healthcare systems with efficient DevOps strategies.",
        alt: "Healthcare IT",
        icon: check,
      },
      {
        title: "E-commerce",
        desc: "Optimizing online retail experiences using cutting-edge DevOps practices.",
        alt: "E-commerce",
        icon: check,
      },
      {
        title: "Manufacturing",
        desc: "Driving operational excellence in manufacturing with tailored DevOps approaches.",
        alt: "Manufacturing",
        icon: check,
      }
    ],
  },
];

export const devops_banner_data = {
  title: "DevOps",
  desc: "We provide seamless DevOps solutions that accelerate development cycles, enhance collaboration, and drive efficiency. Transform your software delivery process with our tailored DevOps services.",
  image: devopsHero,
  properties: [
    {
      title: "Efficient Development Workflow",
      desc: "Streamline your development process with DevOps. Discover efficient practices that merge development and operations, accelerating software delivery without compromising quality.",
    },
    {
      title: "Continuous Integration and Deployment:",
      desc: "Embrace DevOps for automated code integration, testing, and deployment, ensuring an agile development lifecycle.",
    },
    {
      title: "Collaborative Culture:",
      desc: "Foster collaboration between teams. Explore how DevOps cultivates a culture of shared responsibility, enhancing communication and synergy across development, operations, and beyond.",
    },
  ],
};

