 


import onshoreHero from "../img/onshoreHero.svg"
import staffPerson from "../icons/staff-person.svg";
import check from "../icons/check.svg"; 
import aiAdvisor from "../img/ai-advisor.svg";
import generativeAiMain from "../img/generative-ai-main.svg";
import dataManagement from "../img/data-management.svg";
 
 




export const contingent_page_data = [
  {
    service_title: "Contingent Staffing",
    service_desc: "In the dynamic landscape of business, agility is paramount. Our Contingent Staffing Solutions empower your organization to adapt swiftly to changing demands, seize new opportunities, and maintain peak performance. Whether you're aiming to bridge temporary talent gaps or enhance project teams, our specialized staffing approach provides you with the right professionals at the right time.",
    image: aiAdvisor,
    service_items: [
      {
        title: "Temporary Staffing",
        desc: "Need talent for a short-term project? Our temporary staffing solutions ensure that you have skilled professionals exactly when you need them.",
        alt: "Temporary Staffing",
        icon: staffPerson,
      },
      {
        title: "Contract-to-Hire",
        desc: "Try before you buy with our contract-to-hire services, allowing you to assess fit before making a long-term commitment",
        alt: "Contract-to-Hire",
        icon: staffPerson,
      },
      {
        title: "Seasonal Staffing",
        desc: "Manage peak seasons with ease through our specialized seasonal staffing services.",
        alt: "Seasonal Staffing",
        icon: staffPerson,
      },
      {
        title: "Specialized Talent Pool",
        desc: "Stay ahead with accurate predictions and forecasts tailored to your business goals.",
        alt: "Specialized Talent Pool",
        icon: staffPerson,
      },
      
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc: "We're not just a service provider – we're your dedicated partner in propelling your business to new heights. Here's why choosing us is a decision you won't regret",
    image: generativeAiMain,
    service_items: [
      {
        title: "Agile Solutions",
        desc: "Fast and responsive staffing to meet your evolving needs.",
        alt: "Agile Solutions",
        icon: check,
      },
      {
        title: "Quality Talent",
        desc: "Rigorous screening and selection to provide only the best match.",
        alt: "Quality Talent",
        icon: check,
      },
      {
        title: "Cost-Effective",
        desc: "Tailored solutions that align with your budget and project requirements.",
        alt: "Cost-Effective",
        icon: check,
      },
      {
        title: "Trusted Partnership",
        desc: "We work closely with you to understand your unique needs and deliver accordingly",
        alt: "Trusted Partnership",
        icon: check,
      },
    ],
  },
  {
    service_title: "Industries We Serve",
    service_desc: "Our expertise goes beyond providing solutions; we understand the unique dynamics of various industries. Our comprehensive offerings are designed to cater to the specific needs and challenges of different sectors, ensuring that each client receives a solution that's finely tuned to their industry landscape. Discover how we make a difference in the following industries",
    image: dataManagement,
    service_items: [
      {
        title: "Technology & IT",
        desc: "In the fast-paced world of technology, staying ahead requires innovation and agility. We provide IT solutions that streamline operations, enhance security, and drive innovation",
        alt: "Healthcare",
        icon: check,
      },
      {
        title: "Retail",
        desc: "Elevating customer experiences with data-driven strategies, reshaping the future of retail.",
        alt: "Retail",
        icon: check,
      },
      {
        title: "Financial Services",
        desc: "Empowering better fiscal outcomes through data analytics, shaping intelligent financial decisions.",
        alt: "Financial Services",
        icon: check,
      },
      {
        title: "Manufacturing & Supply Chain",
        desc: "Optimizing efficiency with actionable data solutions, redefining manufacturing and supply chain processes.",
        alt: "Financial Services",
        icon: check,
      }
    ],
  },
   
];


export const onshoring_staff_page_data = [
  {
    service_title: "Onshore Staffing",
    service_desc: "We connect talent with opportunity right where you are, fostering collaboration, efficiency, and growth within your local landscape.",
    image: onshoreHero,
    service_items: [
      {
        title: "Temporary & Permanent Staffing",
        desc: "Flexible solutions to meet your changing needs, be it short-term projects or permanent placements.",
        alt: "Temporary & Permanent Staffing",
        icon: staffPerson,
      },
      {
        title: "Specialized Recruitment",
        desc: "Focused recruitment in key sectors to find the perfect match for your specific industry needs.",
        alt: "Specialized Recruitment",
        icon: staffPerson,
      },
      {
        title: "Compliance & Regulatory Alignment",
        desc: "Our onshore solutions adhere to local regulations, ensuring a seamless integration with your business operations.",
        alt: "Compliance & Regulatory Alignment",
        icon: staffPerson,
      },
      {
        title: "Customized Onboarding & Training",
        desc: "Personalized onboarding and training programs to ensure a smooth transition for new hires.",
        alt: "Customized Onboarding & Training",
        icon: staffPerson,
      }, 
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc: "Experience the advantages of Onshore Staffing with InfoVertex",
    image: generativeAiMain,
    service_items: [
      {
        title: "Local Insights",
        desc: "Benefit from local market knowledge and cultural alignment.",
        alt: "Local Insights",
        icon: check,
      },
      {
        title: "Rapid Response",
        desc: "Quick and efficient staffing solutions tailored to your immediate needs.",
        alt: "Rapid Response",
        icon: check,
      },
      {
        title: "Quality & Expertise",
        desc: "Access to a vast pool of highly qualified professionals across various fields.",
        alt: "Cost-Effective",
        icon: check,
      },
      {
        title: "Personalized Service",
        desc: "A dedicated team working closely with you to understand and meet your unique requirements.",
        alt: "Personalized Service",
        icon: check,
      },
    ],
  },
  {
    service_title: "Industries We Serve",
    service_desc: "Our expertise extends across a spectrum of industries, enabling us to deliver tailored solutions that meet the unique challenges and opportunities specific to each sector. With a proven track record of success and a commitment to innovation, we proudly serve the following industries",
    image: dataManagement,
    service_items: [
      {
        title: "Technology & IT",
        desc: "Empowering innovation through tailored IT solutions for sustained growth",
        alt: "Technology & IT",
        icon: check,
      },
      {
        title: "Healthcare",
        desc: "Elevating patient care and operational efficiency through specialized technology solutions.",
        alt: "Retail",
        icon: check,
      },
      {
        title: "Finance & Banking",
        desc: "Navigating financial landscapes with precision and trust through advanced technology.",
        alt: "Finance & Banking",
        icon: check,
      },
      {
        title: " Construction & Engineering",
        desc: "Engineering success through streamlined project management and efficient technology integration.",
        alt: "Construction & Engineering",
        icon: check,
      }
    ],
  },
   
];


export const offshore_Staff_page_data = [
  {
    service_title: "Offshore Staffing",
    service_desc: "InfoVertex is your gateway to global talent. We specialize in Offshore Staffing solutions, connecting you to a world of expertise, efficiency, and value.",
    image: aiAdvisor,
    service_items: [
      {
        title: "Dedicated Offshore Teams",
        desc: "Build dedicated offshore teams tailored to fit your project needs and business goals.",
        alt: "Dedicated Offshore Teams",
        icon: staffPerson,
      },
      {
        title: "Specialized Talent Recruitment",
        desc: "Access specialized skill sets from around the globe for your specific industry requirements.",
        alt: "Specialized Talent Recruitment",
        icon: staffPerson,
      },
      {
        title: "Cost-Effective Solutions",
        desc: "Optimize your budget with our cost-effective offshore staffing, without compromising on quality.",
        alt: "Cost-Effective Solutions",
        icon: staffPerson,
      },
      {
        title: "Seamless Integration",
        desc: "Smooth integration with your existing operations, ensuring that distance never becomes a barrier.",
        alt: "Seamless Integration",
        icon: staffPerson,
      }, 
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc: "Choose InfoVertex for a rewarding Offshore Staffing experience",
    image: generativeAiMain,
    service_items: [
      {
        title: "Global Reach",
        desc: "Leverage international talent for competitive advantage.",
        alt: "Global Reach",
        icon: check,
      },
      {
        title: "Quality Assurance",
        desc: "Rigorous selection processes to ensure you get the best fit.",
        alt: "Quality Assurance",
        icon: check,
      },
      {
        title: "Cultural Alignment",
        desc: "Bridging cultural differences for effective collaboration.",
        alt: "Cultural Alignment",
        icon: check,
      },
      {
        title: "Complete Support",
        desc: "From recruitment to onboarding, we handle it all.",
        alt: "Complete Support",
        icon: check,
      },
    ],
  },
  {
    service_title: "Industries We Serve",
    service_desc: "We offer specialized offshore staffing solutions tailored to a range of industries. Our commitment to excellence and innovation enables us to provide you with the right talent and expertise you need",
    image: dataManagement,
    service_items: [
      {
        title: "Software Development & IT",
        desc: "Fueling your tech projects with skilled professionals who bring your software dreams to life.",
        alt: "Software Development & IT",
        icon: check,
      },
      {
        title: "Healthcare BPO",
        desc: "Enhancing healthcare operations with dedicated offshore teams, ensuring seamless processes and improved patient experiences.",
        alt: "Healthcare BPO",
        icon: check,
      },
      {
        title: "Customer Support & Call Centers",
        desc: "Providing exceptional customer service through offshore staff trained to represent your brand with care and proficiency.",
        alt: "Customer Support & Call Centers",
        icon: check,
      },
      {
        title: "Finance & Accounting",
        desc: "Navigating financial complexities with offshore experts, ensuring accurate accounting and streamlined financial processes.",
        alt: "Finance & Accounting",
        icon: check,
      }
    ],
  },
   
];


