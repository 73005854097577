import check from "../icons/check.svg";
import daService from "../img/da-service.svg";
import advanceInt from "../icons/advance-int.svg";
import modernData from "../img/modern-data.svg";
import dataManagement from "../img/data-management.svg";
import dataHero from "../img/dataHero.svg";
export const data_and_analytics_page_data = [
  {
    service_title: "Data & Analytics Strategy",
    service_desc:
            "We transform raw data into actionable insights, enabling data-driven decision-making for business growth. Our strategy optimizes data collection and analysis, equipping your organization with the tools for strategic success.",
    image: daService,
    service_items: [
      {
        title: "Data Integration & Processing",
        desc: "Seamlessly aggregate and process data from various sources to create a unified view.",
        alt: "Data Integration & Processing",
        icon: advanceInt,
      },
      {
        title: "Business Intelligence & Reporting",
        desc: "Leverage intuitive dashboards, real-time reports, and predictive analytics to make informed decisions..",
        alt: "Business Intelligence & Reporting",
        icon: advanceInt,
      },
      {
        title: "Big Data Solutions",
        desc: "Harness the power of big data with scalable and robust solutions tailored to your needs.",
        alt: "Big Data Solutions",
        icon: advanceInt,
      },
      {
        title: "Machine Learning & AI",
        desc: "Implement advanced machine learning algorithms and AI to discover hidden patterns and trends.",
        alt: "Machine Learning & AI",

        icon:advanceInt,
      }
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc: "Elevate your data game with InfoVertex: Where modern data strategies and cutting-edge analytics converge, propelling your business ahead in today's data-driven landscape.",
    image: modernData,
    service_items: [
      {
        title: "Expertise",
        desc: "Our team of data scientists and analysts brings deep domain expertise to every project.",
        alt: "Expertise",
        icon: check,
      },
      {
        title: "Custom Solutions",
        desc: "We design solutions that align with your unique business objectives.",
        alt: "Custom Solutions",
        icon: check,
      },
      {
        title: "Security & Compliance",
        desc: "Your data's integrity is our priority, and we adhere to the highest security standards.",
        alt: "Security & Compliance",
        icon: check,
      },
      {
        title: "Continuous Support",
        desc: "Benefit from our ongoing support to ensure your data continues to work for you.",
        alt: "Continuous Support",
        icon: check,

      }
    ],
  },

  {
    service_title: "Industries We Serve",
    service_desc: "InfoVertex specializes in delivering tailored data and analytics solutions across diverse sectors, including healthcare, retail, finance, and supply chain. Our insights drive efficiency and growth in these critical industries.",
    image: dataManagement,
    service_items: [
      {
        title: "Healthcare",
        desc: "Driving enhanced patient outcomes through data-driven insights, revolutionizing the healthcare landscape.",
        alt: "Healthcare",
        icon: check,
      },
      {
        title: "Retail",
        desc: "Elevating customer experiences with data-driven strategies, reshaping the future of retail.",
        alt: "Retail",
        icon: check,
      },
      {
        title: "Financial Services",
        desc: "Empowering better fiscal outcomes through data analytics, shaping intelligent financial decisions.",
        alt: "Financial Services",
        icon: check,
      },
      {
        title: "Manufacturing & Supply Chain",
        desc: "Optimizing efficiency with actionable data solutions, redefining manufacturing and supply chain processes.",
        alt: "Financial Services",
        icon: check,
      }
    ],
  },

   
];
export const data_and_analytics_banner_data = {
  title: "Data and Analytics",
  desc: "At InfoVertex, where data drives decisions. Unlock insights, fuel growth, and transform your business with our cutting-edge data and analytics solutions. We turn complex data into actionable intelligence.",
  image: dataHero,
  properties: [
    {
      title: "Modern Data Stack",
      desc: "Discover the Modern Data Stack - your gateway to streamlined data collection, storage, and analysis. Explore how businesses are leveraging this tech-savvy approach to turn data into actionable insights.",
    },
    {
      title: "Cloud Data Transformation",
      desc: "Unleash the power of cloud-based data transformation. Learn how cloud platforms are revolutionizing ETL processes and data pipelines, accelerating decision-making and driving innovation.",
    },
    {
      title: "Reporting and Visualization",
      desc: "Transform data into actionable strategies through impactful reporting and visualization techniques. Master the art of conveying insights with intuitive visuals that empower effective decision-making.",
    },
  ],
};
