import React from "react";
import { FaArrowRight } from "react-icons/fa";
import homeOverview from "../../img/ai-provided.jpg";
import betterDecision from "../../icons/better-decisions.svg";
//import highQuality from "../../icons/quality-icon.png";
import highQuality from "../../icons/quality-medal-svgrepo-com.svg";


const Overview = () => {
    return (
        <div id="overview" className = "pt-3"> 


    <div className="home-overview" > 
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-between align-items-center">
            <img src={homeOverview} alt="Future of AI" className="img-fluid" />
          </div>
          <div className="col-lg-6 mt-3 mt-md-0">
                    <h2 className="fw-medium">Power your enterprise with analytics, artificial intelligence, and innovation.</h2>

            
            <div className="d-flex mt-5">
              <div className="left-icon">
                <img
                  src={betterDecision}
                  alt="AI Decisions"
                  className="img-fluid"
                />
              </div>
              <div className="ms-4  ">
                <h3 className="fw-light">Strategy</h3>
                <p>
                Boost your market advantage through AI-Informed Analysis and intelligent decision-making.

                </p>
              </div>
            </div>

            <div className="right-content">
              <ul>
                <li>
                  <div className="li-icon">
                    <FaArrowRight className="icon" />
                  </div>
                  <p>
                  Our comprehensive offerings include everything from resilient data streams and foresight-driven models to engaging visual displays, all aimed at fueling your triumph in the data-driven landscape.

                  </p>
                </li>
 
              </ul>
            </div>

            <div className="d-flex mt-5">
              <div className="left-icon">
                <img
                  src={highQuality}
                  alt="AI Decisions"
                  className="img-fluid"
                />
              </div>
              <div className="ms-4  ">
                <h3 className="fw-light">High Quality</h3>
                <p>
                Boost your product offerings by integrating AI technologies, positioning yourself ahead of the competition.
                </p>
              </div>
            </div>

            <div className="right-content">
              <ul>
               

                <li>
                  <div className="li-icon">
                    <FaArrowRight className="icon" />
                  </div>
                  <p>
                  Leveraging a team rich in engineering expertise and proficient in AI, we swiftly incorporate generative AI and predictive modeling features into your products. We work closely with you at every stage – from conceptualizing and 	designing to building and implementing.

                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
            </div>

    </div> 

  );
};

export default Overview;
