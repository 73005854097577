import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import partnerBanner from "../img/partner-banner.svg";

import qlik from "../img/qulik-logo.png";
import microsoft from "../img/microsoft-logo.png";
import snowflack from "../img/snowflake-logo.png";
import aws from "../img/aws-logo.png";
import google from "../img/google-logo.png";
import fivetran from "../img/fivetran-logo.png";

const Partnership = () => {
  const title = "Our Partners";
  const desc = "Collaborating to Deliver Innovative Solutions";

  const partners = [
    {
      image: qlik,
      alt: "qlik",
      desc: "AI-driven, real-time and collaborative data and analytics with Qlik platform",
    },
    {
      image: snowflack,
      alt: "snowflack",
      desc: "Delivering ML solutions on Snowflake cloud data platform",
    },
    {
      image: fivetran,
      alt: "fivetran",
      desc: "",
    },
    {
      image: microsoft,
      alt: "microsoft",
      desc: "",
    },
    {
      image: aws,
      alt: "",
      desc: "",
    },
    {
      image: google,
      alt: "google",
      desc: "",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Partnership | Website Name">
      <CompanyBanner image={partnerBanner} title={title} desc={desc} />

      <div className="why-acc">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <h1 className="fw-light text-center">
                Building Strong Technology Partnerships for Business Success
              </h1>
            </div>
          </div>

          <div className="row">
            {partners.map((item, index) => {
              return (
                <div className="col-lg-4 mt-4">
                  <div className="partner-card">
                    <img src={item.image} alt={item.alt} />
                    <p>{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Partnership;
