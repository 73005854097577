import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import ServiceBanner from "./ServiceBanner";
import ServiceCommon from "./ServiceCommon";
import ServiceCommonOdd from "./ServiceCommonOdd";

import {
  managed_it_page_data,
  managed_it_banner_data,
} from "../../utils/managed_it_solutions";
const IT = () => {


  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Digital Engineering | InfoVertex">
      <ServiceBanner
        title={managed_it_banner_data.title}
        desc={managed_it_banner_data.desc}
        properties={managed_it_banner_data.properties}
        image={managed_it_banner_data.image}
      />

      <div className="container">
        <h1 className="text-center fw-light   service-heading">
          Our Managed IT Services
        </h1>
      </div>
      {managed_it_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}
    </Layout>
  );
};

export default IT;
