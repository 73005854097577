import React from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 mt-3">
            <img src={logo} alt="logo" width={150} />
          </div>
          <div className="col-md-6 col-lg-3 mt-3">
            <h4 className="white  ">Company</h4>
            <ul className="footer-ul mt-3 ">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
            </ul>

            <h4 className="white  my-4">Services</h4>
            <ul className="footer-ul mt-3 ">
              <li>
                <Link to="/data-and-analytics"> Data And Analytics </Link>
              </li>
              <li>
                <Link to="/artificial-intelligence">
                  {" "}
                  Artificial Intelligence{" "}
                </Link>
              </li>
              <li>
                <Link to="/business-intelligence">Business Intelligence</Link>
              </li>
              <li>
                <Link to="/devops">DevOps</Link>
              </li>
              <li>
                <Link to="/managed-it-solutions">Managed IT Solutions</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 mt-3">
            <h4 className="white  ">Staffing</h4>
            <ul className="footer-ul mt-3 ">
              <li>
                <Link to="/contingent-staffing">Contingent Staffing</Link>
              </li>
              <li>
                <Link to="/onshore-staffing">Onshore Staffing</Link>
              </li>
              <li>
                <Link to="/offshore-staffing">Offshore Staffing</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 mt-3">
            <h4 className="white ">Connect with Us</h4>
                      <p className="white mb-2 mt-4">
            <h6>
                              <b>United States of America</b></h6>
            </p>
            <p className="white small">
              6160 Warren Parkway Suite # 100
              <br />
              Frisco, TX 75034
            </p>

                      <p className="white mb-2 mt-4">
            <h6>
                              <b>India</b></h6>
            </p>
            <p className="white small">
              211, Maruti Titanium, S.P. Ring Road
              <br />
              Nikol, Ahmedabad - 382350, Gujarat
            </p>

            <p className="white small email mt-4">
           
              <span>Email: </span> <a href="#">contact@infovertex.com</a> 


              <div className="social">
                <ul>
                  <li>
                    <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                  </li>

                  <li>
                    <a href="#"><i class="fa-brands fa-twitter"></i></a>
                  </li>
                </ul>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div>
        <p className="white footer-para">
          <center>
            {" "}
                      <br /> <br /> 2023 InfoVertex Inc. All Rights Reserved.


          </center>
        </p>
      </div>
    </div>
  );
};

export default Footer;
