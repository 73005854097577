import dataAnalytics from "../img/data-analytics.jpg"
import business from "../img/business.jpg"
import ai from "../img/ai.jpg"
import devops from "../img/dev-ops.jpg"
import finance from "../img/finance.jpg"
import staffing from "../img/staffing-story.jpg"


export const stories = [
    {
        image: devops,
        title: "DevOps Integration Made Simple",
        desc: "A tech startup aimed to refine their software deployment procedures. With out DevOps services, we optimized their development and operational workflows, cutting down on their go-to-market timelines and fostering better teamwork among departments."
    },
  {
    image: dataAnalytics,
      title: "Informed Decisions Through Data Analytics",
      desc: "A chain of retail stores sought to leverage their data for making smarter business choices. Through our Data & Analytics offerings, we converted their unprocessed data into valuable insights, leading to more targeted marketing approaches and a boost in revenue.",
  },
    {
        image: business,
        title: "Cloud-Driven Business Transformation",
        desc: "Faced with challenges in scalability and operational efficiency, a multinational corporation found the answers they needed through our customized cloud offerings. These solutions not only elevated their ability to scale but also fine-tuned their expense.",
    },
    {
        image: staffing,
        title: "Flexible Staffing Solutions",
        desc: "Whether it was contingent, onshore, or offshore staffing, our solutions have consistently provided businesses with the right talent at the right time. From a healthcare provider needing specialized onshore staff to a software company expanding with offshore resources, our staffing solutions have always delivered."
    },
  {
    image:finance,
      title: "FinOps for Financial Excellence",
      desc: "A digital retail business sought to better control and fine-tune their cloud-based financial operations. With our FinOPS solutions, we enabled them to realize substantial cost reductions without compromising on efficiency, ensuring that their expenditures were in sync with the business value."

  },
  {
    image: ai,
      title:"Business Potential Through AI and Analytics",
      desc:"A manufacturing sector company sought to utilize AI and BI for both anticipatory maintenance and long-term strategy formulation. Our tailored solutions went beyond simply meeting their needs, paving the way for unprecedented levels of operational efficiency and business expansion."
  },
]