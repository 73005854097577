import React from 'react'

const CarouselCard = ({image,title,desc}) => {
  return (
    <div  className="slick-card">
    <img
      src={image}
      alt={title}
      className="lens-feature-img"
    />
    <h4 className="fw-light my-3">{title} </h4>
    <p> {desc}</p>
  </div>
  )
}

export default CarouselCard