import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import aboutBanner from "../img/about-banner.png";
import FooterTop from "../components/FooterTop";

import Carousel from "react-multi-carousel";
import aboutTimeline from "../img/about-timeline.svg";
import CarouselCard from "../components/CarouselCard";
import {
  ourPurpose,
  responsive,
  slides,
  staffing_solutions,
} from "../utils/about-us";
import ServiceCommon from "./services/ServiceCommon";
import ServiceCommonOdd from "./services/ServiceCommonOdd";

const AboutUs = () => {
  const heading =
    "We welcome the opportunity to discuss how our services can help you achieve your business goals. Feel free to contact us today!";
  const text = "Contact Us";
  const link = "contact-us";
  const title = "Our Goals";
  const desc =
    "At InfoVertex, we are committed to bridging the gap between technology and business success. We have consistently focused on delivering top-tier solutions tailored to meet the evolving needs of our clients across the globe.";


    useEffect(() => {
      window.scrollTo(0, 0);   
    }, []);
  return (
    <Layout title="About Us - InfoVertex">
      <CompanyBanner image={aboutBanner} title={title} desc={desc} />
      <div className="why-acc">
        <div className="container">
          {ourPurpose.map((item, index) => {
            return (
              <div className="approach-card px-lg-5 mb-4" key={index}>
                <div className="row">
                  <div className="col-2 col-md-1">
                    <img
                      src={item.image}
                      alt="Our Purpose"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <div className="service-content">
                      <h5 className="fw-light">{item.title}</h5>
                      <p>
                        {item.desc} '
                        <b className="fw-bold blue">{item?.bold}</b>'{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bg-dcba">
        <div className="container">
          <h1 className="fw-light text-center mb-4">Our Services</h1>
          <Carousel
            responsive={responsive}
            swipeable={true}
            itemClass="px-1"
            draggable={true}
            autoPlaySpeed={1000}
            containerClass="carousel-container"
          >
            {slides.map((item, index) => {
              return (
                <CarouselCard
                  key={index}
                  title={item.title}
                  image={item.img}
                  desc={item.desc}
                />
              );
            })}
          </Carousel>
        </div>
      </div>

      {staffing_solutions.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

      <div className="our-approach padding-last"></div>

      {/* <div className="padding-last why-acc">
        <div className="container">
          <img src={aboutTimeline} alt="Our TimeLine" className="img-fluid" />
        </div>
      </div> */}

    </Layout>
  );
};

export default AboutUs;
