import devopsService from "../icons/devops-service.svg";
import advanceInt from "../icons/advance-int.svg";
import aiAdvisor from "../icons/ai-strategy.svg";
import aiProduct from "../icons/ai-product.svg";
import itIcon from "../icons/it-icon.svg";
import cloudeDb from "../icons/cloud-db.png"
import ourPurpose1 from "../icons/our-purpose-1.svg";
import ourPurpose2 from "../icons/our-purpose-2.svg";
import ourPurpose3 from "../icons/our-purpose-3.svg";
import ourPurpose4 from "../icons/our-purpose-4.svg";
import check from "../icons/check.svg"; 
import productEng from "../img/product-eng.svg";
import offshore from "../img/offshore-staff.svg";
import staffPerson from "../icons/staff-person.svg";


export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const ourPurpose = [
  {
    image: ourPurpose1,
    title: "Our Mission",
    desc: "To empower businesses through innovative technology and staffing solutions that foster growth, efficiency, and success.",
    bold: "",
  },
  {
    image: ourPurpose2,
    title: "Our Vision",
    desc: "To be a global leader in providing seamless integration of technology and human resources, creating lasting value for our clients.",
    bold: "",
  },
 
];


export const slides = [
  {
    img: advanceInt,
    title: "Data and Analytics",
    desc: "We enrich data to drive decisions. Unlock insights, fuel growth, and transform your business with our cutting-edge data and analytics solutions. We turn complex data into actionable intelligence..",
    properties:[
      "Data Integration & Processing",
      "Business Intelligence & Reporting",
      "Big Data Solutions",
      "Machine Learning & AI"
]
  },
  {
    img:  aiAdvisor,
    title: "Artificial Intelligence",
    desc: "We pioneer advanced AI solutions that transform businesses, drive growth, and unlock unprecedented opportunities. Explore the future with us.",

    properties:[
      "Machine Learning Development",
      "Natural Language Processing",
      "Computer Vision & Image Recognition",
      "Predictive Analytics & Forecasting",
      "Robotics & AutomationPredictive Analytics & Forecasting"
]
  },
  {
    img:  aiProduct,
    title: "Business Intelligence",
    desc: "We turn data into actionable insights. We specialize in Business Intelligence solutions that empower organizations to make informed decisions, optimize operations, and drive growth. Uncover the hidden potential of your data with us.",
    properties:[
      "Data Analytics & Visualization",
      "Data Integration & Warehousing",
      "Predictive Modeling & Forecasting",
      "Performance Metrics & KPI Tracking",
      "Custom BI Solutions"
]
  },
  {
    img:  devopsService,
    title: "DevOps",
    desc: "We provide seamless DevOps solutions that accelerate development cycles, enhance collaboration, and drive efficiency. Transform your software delivery process with our tailored DevOps services.",
    properties:[
      "Continuous Integration & Deployment",
      "Infrastructure as Code (IAC)",
      "Monitoring & Performance Optimization",
      "Cloud & Containerization Services", 
]
  },
  {
    img:  itIcon,
    title: "Managed IT Solutions",
    desc: "We unite technology and expertise to deliver streamlined solutions that optimize operations, enhance collaboration, and drive efficiency. Transform your IT landscape with our tailored managed service",
    properties:[
      "24/7 Monitoring and Support",
      "Strategic IT Consulting",
      "Network Management",
      "Cloud & Containerization Services", 
]
  },
  
];




export const staffing_solutions = [
  {
    service_title: "Our Staffing Solutions",
    service_desc:  "We understand that a dynamic and skilled team is the cornerstone of business success. Introducing Our Staffing solutions – a suite of services meticulously designed to address your workforce needs with precision and agility.",
    image:  offshore,
    service_items: [
      {
        title: "Contingent Staffing",
        desc: "Our Contingent Staffing solutions provide the right people at the right time, helping your organization adapt and thrive in a dynamic business environment.",
        alt: "Contingent Staffing",
        icon: staffPerson,
      },
      {
        title: "Onshore Staffing",
        desc: "We connect talent with opportunity right where you are, fostering collaboration, efficiency, and growth within your local landscape.",
        alt: "Onshore Staffing",
        icon: staffPerson,
      },
      {
        title: "Offshore Staffing",
        desc: "We connect talent with opportunity right where you are, fostering collaboration, efficiency, and growth within your local landscape.",
        alt: "Offshore Staffing",
        icon: staffPerson,
      },
      
      
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc:"We're not just a service provider – we're your dedicated partner in propelling your business to new heights. Here's why choosing us is a decision you won't regret",
    image: productEng,
    service_items: [
      {
        title: "Expertise & Innovation",
        desc: "Our skilled team utilizes the latest technologies to deliver innovative solutions.",
        alt: "Expertise & Innovation",
        icon: check,
      },
      {
        title: "Customized Insights",
        desc: " Tailored analytics and reporting to match your unique business context.",
        alt: "Customized Insights",
        icon: check,
      },
      {
        title: "Quality Assurance",
        desc: " We adhere to the highest quality standards, ensuring that our solutions meet your expectations.",
        alt: "Quality Assurance",
        icon: check,
      },
      {
        title: "Global Reach",
        desc: " Serving clients worldwide, we have built a reputation for excellence, innovation, and integrity",
        alt: "Global Reach",
        icon: check,
      },
    ],
  },
];


 