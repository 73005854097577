import React from "react"; 
const ServiceCommon = ({ title, desc, image, properties }) => {
  return (
  

        <div className="row pt-3">
          <div className="col-lg-4 px-md-4">
            <div className="side-card">
              <h5 className="white fw-bold">{title}</h5>
              <p className="white">{desc}</p>
              <img src={image} alt={title} className="img-fluid mt-4" />
            </div>
          </div>
          <div className="col-lg-8 mt-4 mt-lg-0">
            <div className="service-com-card">
              {properties.map((property, index) => (
                <div
                  className={`${
                    index === properties.length - 1
                      ? "service-item service-item-last "
                      : "service-item"
                  }  `}
                  key={index}
                >
                  <div className="row">
                    <div className="col-2 col-md-1">
                      <img
                        src={property.icon}
                        alt={property.alt}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-10 col-md-11">
                      <div className="service-content">
                        <h5 className="fw-bold">{property.title}</h5>
                        <p>{property.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


     
  );
};

export default ServiceCommon;
