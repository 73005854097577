import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import aiGenerated from "../../img/ai-generated.jpg";  
const Approach = () => {
  return (
    <div className="our-approach">
    <div className="container">
      <div className="row">
        <div className="col-md-6 ">
          <div className=" ">
                          <h2 className="fw-medium mb-3">Our Methodology</h2>
            <br></br>
             <h6>Innovation First</h6>
             <p className='small' >We are committed to pushing the boundaries of AI. By constantly exploring new algorithms, models, and technologies, we ensure that our solutions are at the forefront of the industry.</p>
             <h6>Ethical AI</h6>
             <p className='small' >The responsible development and deployment of AI is at the core of our philosophy. We adhere to global standards and best practices to ensure that our AI solutions are transparent, fair, and secure.</p>
             <h6>Collaborative Development</h6>
             <p className='small' >We work hand-in-hand with our clients, understanding their unique needs and crafting AI solutions that are tailored to their specific goals and challenges.</p>
             <h6>Empowering People</h6>
             <p className='small' >While AI is a powerful tool, we believe in the irreplaceable value of human insight. Our approach combines AI with human expertise, ensuring that technology augments, rather than replaces, human capabilities.</p>
             <h6>Scalable Solutions</h6>
             <p className='small' >Our AI-driven solutions are designed to grow with you. From small businesses to large enterprises, we provide scalable systems that evolve to meet your changing needs.</p>

             <h6>End-to-End Support</h6>
             <p className='mb-4 small'>Our relationship with our clients doesn't end at deployment. We provide ongoing support, training, and consultation to ensure that our AI systems continue to deliver exceptional value.</p>


 
          </div>
        </div>
        <div className="col-md-6 mt-5 mt-md-0 d-flex justify-content-center align-items-center">
          <img
            src={aiGenerated}
            alt="Our Approach"
            className="img-fluid approach-img"
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default Approach