import  managedItSol from "../img/managed-it-solutions.svg";
import itIcon from "../icons/it-icon.svg";
import itSolutions from "../img/it-solutions.png";
import securityPng from "../img/security.png";
import check from "../icons/check.svg"; 

export const managed_it_page_data = [
  {
    service_title: "Managed IT solutions",
    service_desc:"In today's fast-paced digital landscape, a reliable and well-maintained IT infrastructure is crucial for the success of any business. Our Managed IT Solutions offer a comprehensive suite of services designed to optimize your technology environment, ensuring smooth operations, enhanced security, and strategic growth. We take the complexity out of IT management, allowing you to focus on what matters most – driving your business forward.",
    image: itSolutions,
    service_items: [
      {
        title: "24/7 Monitoring and Support",
        desc: "Real-time monitoring of systems to detect and fix issues before they impact your operations. Round-the-clock support from our team of IT experts, ensuring continuous uptime.",
        alt: "24/7 Monitoring and Support",
        icon: itIcon,
      },
      {
        title: "Backup and Disaster Recovery",
        desc: "Secure data backup solutions to protect critical information. Disaster recovery plans to get your systems back online quickly after an unexpected event.",
        alt: "Backup and Disaster Recovery",
        icon: itIcon,
      },
      {
        title: "Network Management",
        desc: "Design, implementation, and maintenance of your network infrastructure. Ensuring robust connectivity, performance, and security.",
        alt: "Network Management",
        icon: itIcon,
      },
      {
        title: "Security and Compliance",
        desc: "Implementation of firewalls, anti-virus, and intrusion detection systems. Assistance in meeting industry compliance standards like GDPR, HIPAA, etc.",
        alt: "Security and Compliance",
        icon: itIcon,
      },
      {
        title: "Cloud Solutions and Virtualization",
        desc: "Transitioning to cloud services for scalability and efficiency. Virtualizing servers and applications for cost savings and performance enhancement.",
        alt: "Cloud Solutions and Virtualization",
        icon: itIcon,
      },
      {
        title: "Strategic IT Consulting",
        desc: "Guidance on technology decisions aligned with your business strategy. Regular reviews and roadmaps to ensure optimal technology utilization.",
        alt: "Strategic IT Consulting",
        icon: itIcon,
      },
      
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc:"At inforVertex, we stand out as your preferred partner for Managed IT Solutions due to our unwavering commitment to your success. Our track record of excellence and client-centric approach set us apart in the industry",
    image: securityPng,
    service_items: [
      {
        title: "Personalized Approach",
        desc: " We work closely with you to understand your unique challenges and tailor a solution that fits your needs.",
        alt: "Personalized Approach",
        icon: check,
      },
      {
        title: "Proven Expertise",
        desc: "Our certified professionals have a track record of delivering exceptional service and cutting-edge solutions.",
        alt: "Proven Expertise",
        icon: check,
      },
      {
        title: "Cost-Effective",
        desc: "Managed IT services allow you to control costs and invest in what matters most - growing your business.",
        alt: "Cost-Effective",
        icon: check,
      },
      {
        title: "Trusted Partner",
        desc: "We build long-term relationships and stand by you as technology evolves, supporting your success every step of the way.",
        alt: "Trusted Partner",
        icon: check,
      },
    ],
  },
   
];

export const managed_it_banner_data = {
  title: "Managed IT Solutions",
  desc: "We unite technology and expertise to deliver streamlined solutions that optimize operations, enhance collaboration, and drive efficiency. Transform your IT landscape with our tailored managed service",
  image: managedItSol,
  properties: [
    {
      title: "Comprehensive IT Managemen",
      desc: "Efficiently manage your IT infrastructure. Our solutions encompass everything from network security to software updates, ensuring a secure and smoothly operating digital environment.",
    },
    {
      title: "Proactive Monitoring",
      desc: "Stay ahead of potential issues. Our vigilant monitoring systems track performance and detect anomalies, enabling prompt intervention to prevent disruptions.",
    },
    {
      title: "Scalable Solutions",
      desc: "Grow without limitations. Experience IT solutions that scale with your business, accommodating expansion and technological advancements while maintaining optimal performance.",
    },
  ],
};

