import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import ServiceBanner from "./ServiceBanner";
import ServiceCommonOdd from "./ServiceCommonOdd";
import ServiceCommon from "./ServiceCommon";

import { devops_page_data, devops_banner_data } from "../../utils/devops";
import FooterTop from "../../components/FooterTop";
import { Link } from "react-router-dom";

const DevOps = () => {
  const heading =
    "Join our team of passionate DevOps professionals! Explore career opportunities at InfoVertex and be part of our innovative and dynamic culture";
  const text = "Join Us";
  const link = "career";


  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Data and Analytics | InfoVertex">
      <ServiceBanner
        title={devops_banner_data.title}
        desc={devops_banner_data.desc}
        properties={devops_banner_data.properties}
        image={devops_banner_data.image}
      />
      <div className="container">
        <h1 className="text-center fw-light service-heading">
          Our DevOps Services
        </h1>
      </div>
      {devops_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

      

      <div className="padding-last"></div>
    </Layout>
  );
};

export default DevOps;
