import React, { useState, useEffect } from "react";
import logo from "../../img/logo.png";
import logoalt from "../../img/logo.png";
import { Link, NavLink } from "react-router-dom";
import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const openNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {isNavOpen && (
        <div className="model">
          <div className={isNavOpen ? `model-inner animation` : `model-inner`}>
            <div className="d-flex justify-content-between">
              <div>
                <Link to="/">
                  <img
                    src={logo}
                    className="nav-logo"
                    alt="Top Dog Contractors"
                  />
                </Link>
              </div>

              <div>
                <RxCross2 className="nav-cross" onClick={closeNav} />
              </div>
            </div>

            <div className="list">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="#" onClick={closeNav}>
                    Services
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/data-and-analytics">
                        Data and Analytics
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/artificial-intelligence">
                        Artificial Intelligence
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/business-intelligence">
                        Business Intelligence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/devops">DevOps</NavLink>
                    </li>
                    <li>
                      <NavLink to="/managed-it-solutions">
                        Managed IT Solutions
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to="#" onClick={closeNav}>
                    Staffing
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/contingent-staffing">
                        Contingent Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/onshore-staffing">Onshore Staffing</NavLink>
                    </li>
                    <li>
                      <NavLink to="/offshore-staffing">
                        Offshore Staffing
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink to="#" onClick={closeNav}>
                    Insights
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/client-stories">Client Stories</NavLink>
                     </li>
                                      <li>
                                          <NavLink to="/career">Career</NavLink>
                                      </li>


                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className={`header fixed-top ${scrolling ? "scrolled" : ""}`}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-div">
              <Link to="/">
                <img
                  src={`${scrolling ? logoalt : logo}`}
                  className={`${scrolling ? "logo-alt" : "logo"}`}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="nav-links">
              <ul className="links-color d-flex">

                <li>
                  <NavLink to="/">Home</NavLink>
                </li>

                <li className="custom-dropdown">
                  <NavLink to="#">
                    Services <BsChevronDown className="dropdown-icon" />
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/data-and-analytics">
                        Data and Analytics
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/artificial-intelligence">
                        Artificial Intelligence
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/business-intelligence">
                        Business Intelligence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/devops">DevOps</NavLink>
                    </li>
                    <li>
                      <NavLink to="/managed-it-solutions">
                        Managed IT Solutions
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="custom-dropdown">
                  <NavLink to="#">
                    Staffing <BsChevronDown className="dropdown-icon" />
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/contingent-staffing">
                        Contingent Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/onshore-staffing">Onshore Staffing</NavLink>
                    </li>
                    <li>
                      <NavLink to="/offshore-staffing">
                        Offshore Staffing
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="custom-dropdown">
                  <NavLink to="#">
                    Insights <BsChevronDown className="dropdown-icon" />
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/client-stories">Client Stories</NavLink>
                                      </li>
                                      <li>
                                          <NavLink to="/career">Career</NavLink>
                                      </li>
                  </ul>
                </li>

                {/* <li className="custom-dropdown">
                  <NavLink to="#">
                    Company <BsChevronDown className="dropdown-icon" />
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/partnership">Partnership</NavLink>
                    </li>

                    <li>
                      <NavLink to="/career">Career</NavLink>
                    </li>
                  </ul>
                </li> */}
              </ul>

              <Link to="/contact-us" className="banner-btn">
                <BsArrowRight className="btn-icon" />
                <span>Write to us</span>
              </Link>
            </div>

            <div
              onClick={openNav}
              className={`hamburger  ${scrolling ? "hamburger-scrolled" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
