import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import contact_banner from "../img/contact-us-banner.svg";
import $ from "jquery";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Contact = () => {
  //For browse functionality start
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  //For browse functionality end

  //Browse functionality part 2
  const [messageSent, setMessageSent] = useState(false);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    resume: null,
  });

  const [sending, setSending] = useState(false);

  const handleInputChange = (event) => {
    const { name, type, files } = event.target;
  
    if (type === 'file') {
      const selectedFile = files.length > 0 ? files[0] : null;
  
      setContact({
        ...contact,
        [name]: selectedFile,
      });
    } else {
      const { value } = event.target;
      setContact({
        ...contact,
        [name]: value,
      });
    }
    console.log("resume",contact)
  };
  

  console.log(contact)


  const changeHandeler = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
  
    try {
      setSending(true);
  
      const formData = new FormData();
      formData.append("name", contact.name);
      formData.append("email", contact.email);
      formData.append("mobile", contact.mobile);
      formData.append("company", contact.company);
      formData.append("message", contact.message);
  
      if (contact.resume) {
        formData.append('resume', contact.resume);
      }
  
      $.ajax({
        method: "POST",
        url: `https://formsubmit.co/ajax/contact@infovertex.com`,
        // dataType: "json",
        data: formData,
        processData: false,
        contentType: false,
        enctTpe:"multipart/form-data",
        success: (data) => {
          console.log("success");
          setSending(false);
          setMessageSent(true);
          setContact({
            name: "",
            email: "",
            mobile: "",  
            company: "",
            message: "",
            resume: null,  
          });
        },
        error: (err) => {
          console.log("error");
          setSending(false);
          setMessageSent(false);
        },
      });
    } catch (error) {
      console.log(error);
      setSending(false);
      setMessageSent(false);
    }
  };
  

  const title = "Career";
    const desc = "We're a family committed to excellence and innovation. We're always looking for passionate, dedicated individuals who are eager to make an impact and drive success. If you're looking to be part of a dynamic, forward-thinking team and want to contribute to meaningful projects, you're in the right place.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <CompanyBanner image={contact_banner} title={title} desc={desc} />

      <div className="why-acc">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mt-4 mt-lg-0">
              <div className="contact-card">
                <div className="row">
                  <form   method="POST"  enctype="multipart/form-data"  onSubmit={submit}  >
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          required
                          name="name"
                          value={contact.name}
                          onChange={changeHandeler}
                          className="custom-field"
                          placeholder="Your Name *"
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="email"
                          required
                          name="email"
                          onChange={changeHandeler}
                          value={contact.email}
                          className="custom-field"
                          placeholder="Your Email *"
                        />
                      </div>
                    </div>

                    <div className="row mt-md-4">
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          required
                          name="mobile"
                          value={contact.mobile}
                          onChange={changeHandeler}
                          className="custom-field"
                          placeholder="Your Mobile *"
                        />
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          required
                          name="company"
                          value={contact.company}
                          onChange={changeHandeler}
                          className="custom-field"
                          placeholder="Company Name *"
                        />
                      </div>
                    </div>

                    <div className="row mt-md-4">
                      <div className="col-md-12 my-2">
                        <textarea
                          className="custom-field"
                          name="message"
                          value={contact.message}
                          onChange={changeHandeler}
                          placeholder="Message"
                          rows="5"
                        ></textarea>
                      </div>

                      <div className="row mt-md-4">
                        <div className="col-md-6 mt-2">
                          <input
                            type="file"
                            name="resume"
                            onChange={handleInputChange}
                            className="custom-file-input"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 text-center mt-5">
                        <button
                          className="service-btn"
                          type="submit"
                          {...(sending && { disabled: true })}
                        >
                          <BsArrowRight className="btn-icon" />

                          {sending ? "Sending..." : "Send Message"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
