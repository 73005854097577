import check from "../icons/check.svg"; 
import aiEnablement from "../img/ai-enablement.svg";
import aiProduct from "../icons/ai-product.svg";
import productEng from "../img/product-eng.svg";
import dataManagement from "../img/data-management.svg";
import digitalEng from "../img/digital-eng.svg";


export const business_int_page_data = [
  {
    service_title: "AI Enablement",
    service_desc:
      "Infusing Artificial Intelligence into products, enabling new digital experiences, and business models.",
    image: aiEnablement,
    service_items: [
      {
        title: "Data Analytics & Visualization",
        desc: "Data Analytics & Visualization",
        alt: "Data Analytics & Visualization",
        icon: aiProduct,
      },
      {
        title: "Data Integration & Warehousing",
        desc: "Aggregate and harmonize data from multiple sources for seamless access and analysis.",
        alt: "Data Integration & Warehousing",
        icon: aiProduct,
      },
      {
        title: "Predictive Modeling & Forecasting",
        desc: "Stay ahead with predictive models that help you anticipate trends and opportunities.",
        alt: "Predictive Modeling & Forecasting",
        icon: aiProduct,
      },
      {
        title: "Performance Metrics & KPI Tracking",
        desc: "Monitor key performance indicators and gain real-time insights into your business health.",
        alt: "Performance Metrics & KPI Tracking",
        icon: aiProduct,
      },
      {
        title: "Custom BI Solutions",
        desc: "Tailored intelligence solutions designed to align with your specific business needs and goals",
        alt: "Custom BI Solutions",
        icon: aiProduct,

      }
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc:"We're not just a service provider – we're your dedicated partner in propelling your business to new heights. Here's why choosing us is a decision you won't regret",
    image: productEng,
    service_items: [
      {
        title: "Expertise & Innovation",
        desc: "Our skilled team utilizes the latest technologies to deliver innovative solutions.",
        alt: "Expertise & Innovation",
        icon: check,
      },
      {
        title: "Customized Insights",
        desc: " Tailored analytics and reporting to match your unique business context.",
        alt: "Customized Insights",
        icon: check,
      },
      {
        title: "Secure & Scalable",
        desc: " Robust solutions that grow with your business while ensuring data integrity and security.",
        alt: "Secure & Scalable",
        icon: check,
      },
      {
        title: "Ongoing Support & Training",
        desc: " We provide continuous support and training to ensure you get the most from your BI investment.",
        alt: "Ongoing Support & Training",
        icon: check,
      },
    ],
  },
  {
    service_title: "Industries We Serve",
    service_desc: "InfoVertex specializes in delivering tailored data and analytics solutions across diverse sectors, including healthcare, retail, finance, and supply chain. Our insights drive efficiency and growth in these critical industries.",
    image: dataManagement,
    service_items: [
      {
        title: "Healthcare",
        desc: "Driving enhanced patient outcomes through data-driven insights, revolutionizing the healthcare landscape.",
        alt: "Healthcare",
        icon: check,
      },
      {
        title: "Retail",
        desc: "Elevating customer experiences with data-driven strategies, reshaping the future of retail.",
        alt: "Retail",
        icon: check,
      },
      {
        title: "Financial Services",
        desc: "Empowering better fiscal outcomes through data analytics, shaping intelligent financial decisions.",
        alt: "Financial Services",
        icon: check,
      },
      {
        title: "Manufacturing & Supply Chain",
        desc: "Optimizing efficiency with actionable data solutions, redefining manufacturing and supply chain processes.",
        alt: "Financial Services",
        icon: check,
      }
    ],
  },
   
];

export const business_int_banner_data = {
  title: "Business Intelligence",
  desc: "We specialize in Business Intelligence solutions that empower organizations to make informed decisions, optimize operations, and drive growth. Uncover the hidden potential of your data with us.",
  image: digitalEng,
  properties: [
    {
      title: "Data-Driven Insights",
      desc: "Uncover actionable insights from your business data. Our Business Intelligence solutions transform raw information into strategic opportunities, empowering informed decision-making.",
    },
    {
      title: "Interactive Visualizations",
      desc: "Visualize data like never before. Experience interactive dashboards and reports that make complex information intuitive, enabling deeper understanding and effective communication.",
    },
    {
      title: "Real-Time Analytics",
      desc: "Stay ahead in real time. Harness the power of up-to-the-minute analytics to seize opportunities, mitigate risks, and adapt swiftly in today's dynamic business landscape.",
    },
  ],
};
