import Layout from "../../components/layout/Layout";
import Banner from "./Banner";
import Approach from "./Approach";
import Overview from "./Overview";
import Services from "./Services";
 
import Contact from "../../components/ContactForm";
const Homepage = () => {
  return (
    <Layout title="Homepage | InfoVertex">
      <Banner />
      <Overview />
      <Approach />
      <Services />
   
      <Contact />
    </Layout>
  );
};

export default Homepage;
