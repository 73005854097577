import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import Banner100 from "../../components/Banner100";
 
import offshore from "../../img/offshore-staff.svg";
import ServiceCommon from "../services/ServiceCommon";
import ServiceCommonOdd from "../services/ServiceCommonOdd";
import { offshore_Staff_page_data } from "../../utils/staffing";
import FooterTop from "../../components/FooterTop";
import { Link } from "react-router-dom";

const Offshore = () => {
  const title = "Offshore Staffing";
  const desc ="We specialize in Onshore Staffing solutions. We connect talent with opportunity right where you are, fostering collaboration, efficiency, and growth within your local landscape.";


  const heading =
    "Join our team of passionate DevOps professionals! Explore career opportunities at InfoVertex and be part of our innovative and dynamic culture";
  const text = "Join Us";
  const link = "career";

  
  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout title="Offshore Stafing | InfoVertex">
      <Banner100 title={title} desc={desc} image={offshore} />

      <div className="container">
        <h1 className="text-center fw-light service-heading">Our Offshore Staffing Solutions</h1>
      </div>


      {offshore_Staff_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}




      <div className="padding-last"></div>
    </Layout>
  );
};

export default Offshore;
