import React from "react";
 
const CompanyBanner = ({title,image,desc}) => {
  return (
    <div className="server-banner company-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex align-items-center">
            <div>
            <h1 className="fw-light white">{title}</h1>
            <p className="white fw-medium">
              {desc}
            </p>
            </div>
            
          </div>
          <div className="col-lg-8 mt-4 mt-lg-0 d-flex justify-content-center">
            <img src={image} alt={title} className="img-fluid company-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBanner;
