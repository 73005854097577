import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ContactForm = () => {
  return (
    <div className="contact">

      </div>
    
  );
};

export default ContactForm;
