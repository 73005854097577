import React from "react"; 
import { FaArrowRight } from "react-icons/fa";

const TabCard = ({ image, title, properties }) => {
  return (
    <div className="approach-card py-5">
      <div className="row">
        <div className="col-lg-6">
          <h1 className="fw-light">{title}</h1>

          <ul className="tab-card-ul">
            {properties.map((property, index) => {
              return (
                <li key={index}>
                  <div className="li-icon">
                    <FaArrowRight className="icon" />
                  </div>
                  <p>{property}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-lg-6 mt-4 d-flex align-items-center justify-content-center mt-lg-0">
          <img src={image} alt={title} className="img-fluid approach-img" />
        </div>
      </div>
    </div>
  );
};

export default TabCard;
