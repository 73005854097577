/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import itIcon from "../../icons/it-icon.svg";
import ai from "../../icons/ai.svg";
import da from "../../icons/da.svg";
import advanceInt from "../../icons/advance-int.svg";
import de from "../../icons/de.svg";
import devopsService from "../../icons/devops-service.svg";
const Services = () => {
  return (
    <div className="our-services">
      <div className="container">
        <div className="row" >
          <div className="col-md-10">
                      <h2 className="fw-medium mb-3">How we transform your business</h2>
          </div>
        </div>

       
        <div>
          <div className="static-portion">
            <div className="row ">
                  <div className="col-lg-8 col-xl-9 "
                data-bs-spy="scroll"
                data-bs-target="#services">

            <div className="pt-5" id="data-and-analytics"  >
                  <div className="service-card">
                    <div className="ser-icon">
                      <img
                        src={advanceInt}
                        alt="data-and-analytics"
                        className="img-fluid"
                      />
                    </div>




                    <div className="serv-content">
                      <div className="inner">
                        <h3 className="fw-light mb-4">Data And Analytics</h3>
                        <p className="mb-4">
                          Our services integrate and process data, provide
                          insightful business intelligence and reporting,
                          harness big data with tailored solutions, and utilize
                          machine learning and AI to uncover hidden patterns,
                          all aimed at advancing your business objectives.
                        </p>

                        <Link to="/data-and-analytics" className="service-btn ">
                          <BsArrowRight className="btn-icon" />
                          <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5" id="artificial-intelligence">
                  <div className="service-card">
                    <div className="ser-icon">
                      <img
                        src={ai}
                        alt="Artificial Intelligence"
                        className="img-fluid"
                      />
                    </div>
                    <div className="serv-content">
                      <div className="inner">
                        <h3 className="fw-light mb-4">
                          Artificial Intelligence
                        </h3>
                        <p className="mb-4">
                          Customize your business with tailored Machine
                          Learning, intuitive Natural Language Processing,
                          advanced visual insights, predictive forecasting, and
                          AI-driven automation.
                        </p>

                        <Link
                          to="/artificial-intelligence"
                          className="service-btn "
                        >
                          <BsArrowRight className="btn-icon" />
                          <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5" id="business-intelligence">
                  <div className="service-card">
                    <div className="ser-icon">
                      <img
                        src={da}
                        alt="Data and Analytics"
                        className="img-fluid"
                      />
                    </div>
                    <div className="serv-content">
                      <div className="inner">
                        <h3 className="fw-light mb-4">Business Intelligence</h3>
                        <p className="mb-4">
                          Explore and analyze data through intuitive analytics
                          and visualization, integrate and warehouse diverse
                          data sources, anticipate trends with predictive
                          modeling, monitor KPIs in real-time, and benefit from
                          custom Business Intelligence solutions aligned with
                          your specific needs and goals.
                        </p>

                        <Link
                          to="/business-intelligence"
                          className="service-btn "
                        >
                          <BsArrowRight className="btn-icon" />
                          <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5" id="devops">
                  <div className="service-card">
                    <div className="ser-icon">
                      <img
                        src={devopsService}
                        alt="Devops"
                        className="img-fluid"
                      />
                    </div>
                    <div className="serv-content">
                      <div className="inner">
                        <h3 className="fw-light mb-4">DevOps</h3>
                        <p className="mb-4">
                          Automate and accelerate build processes with
                          Continuous Integration & Deployment, efficiently
                          manage resources through Infrastructure as Code (IAC),
                          monitor and optimize application performance, and
                          embrace flexibility with cutting-edge Cloud &
                          Containerization services.
                        </p>

                        <Link to="/devops" className="service-btn ">
                          <BsArrowRight className="btn-icon" />
                          <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5" id="managed-it-solutions">
                  <div className="service-card">
                    <div className="ser-icon">
                      <img
                        src={itIcon}
                        alt="Managed IT Solutions"
                        className="img-fluid"
                      />
                    </div>
                    <div className="serv-content">
                      <div className="inner">
                        <h3 className="fw-light mb-4">Managed IT Solutions</h3>
                        <p className="mb-4">
                          We provide 24/7 system monitoring, robust data backup
                          and recovery, secure network management, adherence to
                          compliance standards, efficient cloud and
                          virtualization solutions, and strategic IT consulting
                          to align with your business needs.
                        </p>

                        <Link
                          to="/managed-it-solutions"
                          className="service-btn "
                        >
                          <BsArrowRight className="btn-icon" />
                          <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
