import React from "react";
import Layout from "../../components/layout/Layout";
import Banner100 from "../../components/Banner100";
import zonoLens from "../../img/zono-lens.svg";
import FooterTop from "../../components/FooterTop";
import why_dcba_lens from "../../img/why-dcba.lens.svg"; 
import Carousel from "react-multi-carousel";
import lens_feature_1 from "../../icons/lens-feature-1.svg";
import lens_feature_2 from "../../icons/lens-fature-2.svg";
import lens_feature_3 from "../../icons/lens-feature-3.svg";
import lens_feature_4 from "../../icons/lens-feature-4.svg";
import CarouselCard from "../../components/CarouselCard";

import advanced_computer from "../../img/advanced-computer.svg";

import advanced_computer_1 from "../../icons/advance-computer-1.svg";
import advanced_computer_2 from "../../icons/advance-computer-2.svg";
import advanced_computer_3 from "../../icons/advance-computer-3.svg";
import advanced_computer_4 from "../../icons/advance-computer-4.svg";
import TabCard from "../../components/TabCard";

import solution_tab_1 from "../../img/solution-tab-1.svg";
import solution_tab_2 from "../../img/solution-tab-2.svg";
import solution_tab_3 from "../../img/solution-tab-3.svg";

const AcceleratorTwo = () => {
  const title = "xxxxxxxxxxxxxxx";
  const desc =
    "";

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const slides = [
    {
      img: lens_feature_1,
      title: "Image Enhancement Libraries",
      desc: "",
    },
    {
      img: lens_feature_2,
      title: "Object Detection and Recognition",
      desc: "",
    },
    {
      img: lens_feature_3,
      title: "Annotate and Tag Images",
      desc: "",
    },
    {
      img: lens_feature_4,
      title: "Custom Models",
      desc: "",
    },
  ];

  const advance_computer = [
    {
      icon: advanced_computer_1,
      alt: "Batch Processing",
      title: "Batch Processing –  ",
 
    },
    {
      icon: advanced_computer_2,
      alt: "Automatic Tagging",
      title: "Automatic Tagging – ",
  
    },
    {
      icon: advanced_computer_3,
      alt: "Customizable Workflows",
      title: "Customizable Workflows – ",
    },
    {
      icon: advanced_computer_4,
      alt: "Continuous Learning",
      title: "Continuous Learning –  ",
    },
  ];

  const tab_one_data = {
    image: solution_tab_1,
    title: "InspectionLens",
    properties: [
      
    ],
  };

  const tab_two_data = {
    image: solution_tab_2,
    title: "DocuLens",
    properties: [
    ],
  };

  const tab_three_data = {
    image: solution_tab_3,
    title: "ContractLens",
    properties: [
    ],
  };
  return (
    <Layout title="AcceleratorTwo | InfoVertex">
      <Banner100 title={title} desc={desc} image={zonoLens} />

      <div className="why-acc">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={why_dcba_lens}
                alt=""
                className="img-fluid service-img"
              />
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center">
              <div>
                <h1 className="fw-light mb-4"></h1>
                <p>
 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dcba">
        <div className="container">

          <h1 className="fw-light text-center mb-4">Key Features</h1>
          <Carousel
            responsive={responsive}
            swipeable={true} 
            itemClass="px-1"
            draggable={true}
            autoPlaySpeed={1000}
            containerClass="carousel-container"
          >
            {slides.map((item, index) => {
              return (
                <CarouselCard
                  key={index}
                  title={item.title}
                  image={item.img}
                  desc={item.desc}
                />
              );
            })}
          </Carousel>
        </div>
      </div>

      <div className="why-acc ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src={advanced_computer}
                alt="Advacned Computer Vision"
                className="img-fluid service-img"
              />
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <h1 className="fw-light mb-4">
                Advanced Computer Vision Capabilities
              </h1>

              {advance_computer.map((item, index) => (
                <div className="row my-3" key={index}>
                  <div className="col-2 col-md-1">
                    <img src={item.icon} alt={item.alt} className="img-fluid" />
                  </div>
                  <div className="col-10 col-md-11">
                    <p>
                      <b className="fw-bold">{item.title} </b> {item.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dcba padding-last">
        <div className="container">
          <h1 className="fw-light text-center">Solutions</h1>
          <h2 className="fw-light text-center mb-4">
  
          </h2>
          <div>
            <ul className="navigation-pils" id="pills-tab" role="tablist">
              <li role="presentation">
                <button
                  className="nav-link active"
                  id="tab_no_1"
                  data-bs-toggle="pill"
                  data-bs-target="#target_tab_1"
                  type="button"
                  role="tab"
                  aria-controls="target_tab_1"
                  aria-selected="true"
                >
                  InspectionLens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="tab_no_2"
                  data-bs-toggle="pill"
                  data-bs-target="#target_tab_2"
                  type="button"
                  role="tab"
                  aria-controls="target"
                  aria-selected="false"
                >
                  DocuLens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="tab_no_3"
                  data-bs-toggle="pill"
                  data-bs-target="#target_tab_3"
                  type="button"
                  role="tab"
                  aria-controls="target_tab_3"
                  aria-selected="false"
                >
                  ContractLens
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="target_tab_1"
                role="tabpanel"
                aria-labelledby="tab_no_1"
              >
                <TabCard
                  image={tab_one_data.image}
                  title={tab_one_data.title}
                  properties={tab_one_data.properties}
                />
              </div>
              <div
                className="tab-pane fade"
                id="target_tab_2"
                role="tabpanel"
                aria-labelledby="tab_"
              >
                <TabCard
                  image={tab_two_data.image}
                  title={tab_two_data.title}
                  properties={tab_two_data.properties}
                />
              </div>
              <div
                className="tab-pane fade"
                id="target_tab_3"
                role="tabpanel"
                aria-labelledby="tab_no_3"
              >
                <TabCard
                  image={tab_three_data.image}
                  title={tab_three_data.title}
                  properties={tab_three_data.properties}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTop
        link="contact-us"
        text="Schedual A Demo"
        heading=""
      />
    </Layout>
  );
};

export default AcceleratorTwo;
