import React from 'react'
import hero from "../../img/home-hero.svg";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { TypeAnimation } from "react-type-animation";

const Banner = () => {
  return (
    
    <div className="banner">
        <div className="container"> 
          <div className="row">
            <div className="col-lg-6   d-flex flex-column justify-content-center">
              <h1 className="hero_heading">
              Enriching Data with <span>Intelligence</span>
              </h1>
              <p className="white-para">
              Enriching your data with actionable Business Intelligence using the power of Analytics and Artificial Intelligence, leading toward data-driven decisions.

              </p>

              <div className="animated-heading">
                <TypeAnimation
                  sequence={[
                    "Data and Analytics",
                    1000,
                    "Artificial Intelligence",
                    1000,
                    "Precise Decision Making",
                    1000,
                  ]}
                  wrapper="h2"
                  omitDeletionAnimation={true}
                  speed={1}
                  cursor={false}
                  className="dynamic-heading"
                  repeat={Infinity}
                />
              </div>

              <a href="#overview" className="banner-btn ">
                <BsArrowRight className="btn-icon" />
                <span>More ...</span>
              </a>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <img src={hero} alt="home_hero" className="img-fluid banner-img"  />
            </div>
          </div>
        </div>
      </div>

  )
}

export default Banner