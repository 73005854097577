import check from "../icons/check.svg";
import aiStrategy from "../icons/ai-strategy.svg";
import aiAdvisor from "../img/ai-advisor.svg";
import generativeAiMain from "../img/generative-ai-main.svg";
import dataManagement from "../img/data-management.svg";
import aiHero from "../img/ai-hero.svg";



export const artificial_intelligence_page_data = [
  {
    service_title: "AI Strategy",
    service_desc:
            "We leverage Artificial Intelligence to automate processes, enhance decision-making, and create value across various business functions. Our strategy focuses on implementing AI solutions that are scalable, ethical, and aligned with your organization's goals for long-term success.",
    image: aiAdvisor,
    service_items: [
      {
        title: "Machine Learning Development",
        desc: "Custom algorithms tailored to your needs, turning data into actionable insights.",
        alt: "Machine Learning Development",
        icon: aiStrategy,
      },
      {
        title: "Natural Language Processing",
        desc: "Interact with your users in a more intuitive way through our sophisticated language models.",
        alt: "Natural Language Processing",
        icon: aiStrategy,
      },
      {
        title: "Computer Vision & Image Recognition",
        desc: "Unlock visual insights and automation with cutting-edge image analysis techniques.",
        alt: "Computer Vision & Image Recognition",
        icon: aiStrategy,
      },
      {
        title: "Predictive Analytics & Forecasting",
        desc: "Stay ahead with accurate predictions and forecasts tailored to your business goals.",
        alt: "Predictive Analytics & Forecasting",
        icon: aiStrategy,
      },
      {
        title: "Robotics & AutomationPredictive Analytics & Forecasting",
        desc: "Enhance efficiency and precision through AI-driven robotics and automation solutions.",
        alt: "Robotics & Automation",
        icon: aiStrategy,
      },
    ],
  },
  {
    service_title: "Why Choose InfoVertex?",
    service_desc:
      "We will help you explore the art of possible with AI in the context of your business, and partner with you to put AI into action for your business in a pragmatic way",
    image: generativeAiMain,
    service_items: [
      {
        title: "Cutting-Edge Technology",
        desc: "Utilizing the latest advancements in AI and machine learning.",
        alt: "Cutting-Edge Technology",
        icon: check,
      },
      {
        title: "Industry Expertise",
        desc: "A seasoned team with a deep understanding of various industry applications.",
        alt: "Industry Expertise",
        icon: check,
      },
      {
        title: "Ethical AI Practices",
        desc: "We adhere to responsible and ethical AI principles.",
        alt: "Ethical AI Practices",
        icon: check,
      },
      {
        title: "Custom Solutions",
        desc: "Tailored strategies aligned with your unique business objectives.",
        alt: "Custom Solutions",
        icon: check,
      },
    ],
  },
  {
    service_title: "Industries We Serve",
    service_desc: "InfoVertex specializes in delivering tailored data and analytics solutions across diverse sectors, including healthcare, retail, finance, and supply chain. Our insights drive efficiency and growth in these critical industries.",
    image: dataManagement,
    service_items: [
      {
        title: "Healthcare",
        desc: "Driving enhanced patient outcomes through data-driven insights, revolutionizing the healthcare landscape.",
        alt: "Healthcare",
        icon: check,
      },
      {
        title: "Retail",
        desc: "Elevating customer experiences with data-driven strategies, reshaping the future of retail.",
        alt: "Retail",
        icon: check,
      },
      {
        title: "Financial Services",
        desc: "Empowering better fiscal outcomes through data analytics, shaping intelligent financial decisions.",
        alt: "Financial Services",
        icon: check,
      },
      {
        title: "Manufacturing & Supply Chain",
        desc: "Optimizing efficiency with actionable data solutions, redefining manufacturing and supply chain processes.",
        alt: "Financial Services",
        icon: check,
      }
    ],
  },
   
];

export const artificial_intelligence_banner_data = {
  title: "Artificial Intelligence",
    desc: "At InfoVertex, innovation meets intelligence. We pioneer advanced AI solutions that transform businesses, drive growth, and unlock unprecedented opportunities. Explore the future with us.",
  image: aiHero,
  properties: [
    {
      title: "Pragmatic AI for Business",
      desc: "Unleash the potential of AI in business operations. Explore practical implementations that drive efficiency and innovation, propelling your enterprise forward.",
    },
    {
      title: "Responsible and Trustworthy AI",
      desc: "Prioritizing ethics and accountability in AI. Discover how we ensure AI technologies are developed and deployed with responsibility and trustworthiness at their core.",
    },
    {
      title: "A Value-Conscious Approach",
      desc: "Aligning AI initiatives with core values. Learn how our approach to AI integrates with your mission, delivering meaningful results that resonate with your organization's principles.",
    },
  ],
};