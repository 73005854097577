import React from 'react'
import Layout from '../../components/layout/Layout'
import Banner100 from '../../components/Banner100'
import zonochat from "../../img/dcba-chat.svg";

const AcceleratorFour = () => {

  const title="xxxxxxxxxxxxxxx"
  const desc="An enterprise-grade generative AI platform combining Large Language Models, Knowledge Graphs and Conversational User Experiences to supercharge employee productivity and customer assistance."
  return (
    <Layout title="AcceleratorFour | InfoVertex">
    <Banner100 title={title} desc={desc} image={zonochat} />
    </Layout>
   
  )
}

export default AcceleratorFour