import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import CompanyBanner from "../components/CompanyBanner";
import heroBanner from "../img/client-stories-hero.svg";
import { stories } from "../utils/stories";
import FooterTop from "../components/FooterTop";
const Stories = () => {
  const title = "Client Stories";

  const heading ="Ready to write the next success story? Contact us today to explore how our solutions and services can drive your business forward."
  const text = "Contact Us"
  const link = "contact-us"

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);
  return (
    <Layout>
          <CompanyBanner image={heroBanner} title={title} desc="We take pride in forming long-lasting partnerships with our clients, and nothing makes us happier than seeing our clients thrive. Please read our client stories to see how our solutions have helped transform businesses and lives." />


      <div className="container">
        <h1 className="text-center fw-light service-heading">Our Client Stories</h1>
      </div>

      <div className="why-acc">
        <div className="container">
          <div className="row">
            {stories.map((story, index) => {
              return (
                <div className="col-lg-4 mt-4" key={index}>
                  <div className="story-card">
                    <a href="#">
                      <img
                        src={story.image}
                        alt={story.title}
                        className="img-fluid"
                      />
                    </a>
                    <div className="card-content p-3">
                      <h6 className="fw-bolder">{story.title}</h6>
                      <p>
                        <b>{story.desc}</b>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="padding-last"></div>

      

    </Layout>
  );
};

export default Stories;
