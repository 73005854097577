import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import Banner100 from "../../components/Banner100";
 
import staffing from "../../img/staffing.svg";
import ServiceCommon from "../services/ServiceCommon";
import ServiceCommonOdd from "../services/ServiceCommonOdd";
import { contingent_page_data } from "../../utils/staffing";
import { Link } from "react-router-dom";
import FooterTop from "../../components/FooterTop";

const Contiguent = () => {
  const title = "Contingent Staffing";
  const desc =
    "At InfoVertex, flexibility meets talent. Our Contingent Staffing solutions provide the right people at the right time, helping your organization adapt and thrive in a dynamic business environment.";

  const heading =
    "Looking for opportunities? Contact us to explore exciting career options with us and our clients. ";
  const text = "Contact Us";
  const link = "contact-us";

  useEffect(() => {
    window.scrollTo(0, 0);   
  }, []);

  
  return (
    <Layout title="Contiguent Stafing | InfoVertex">
      <Banner100 title={title} desc={desc} image={staffing} />

      <div className="container">
        <h1 className="text-center fw-light service-heading">
          Our Contingent Staffing Solutions
        </h1>
      </div>

      {contingent_page_data.map((item, index) => {
        console.log(index % 2);
        return (
          <div
            key={index}
            className={`service-common ${
              index % 2 === 0 ? "" : "service-common-odd"
            }`}
          >
            <div className="container">
              {index % 2 === 0 ? (
                <ServiceCommon
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              ) : (
                <ServiceCommonOdd
                  title={item.service_title}
                  desc={item.service_desc}
                  image={item.image}
                  properties={item.service_items}
                />
              )}
            </div>
          </div>
        );
      })}

     

      <div className="padding-last"></div>
    </Layout>
  );
};

export default Contiguent;
